<template>
  <b-container>
    <b-row style="height: 100vh" class="justify-content-center">
      <b-col lg="6">
        <div class="w-100 text-center mb-4">
          <b-card class="mt-4">
            <div class="w-100 text-center mb-4">
              <h4 class="mb-0 font-weight-bold text-primary">
                Register Success!
              </h4>
            </div>
            <p class="mb-0">
              Your account is registered successfully. Please kindly check your
              email for a confirmation link from us to activate your account.
            </p>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <app-spinner ref="spinner"></app-spinner>
  </b-container>
</template>

<script>
  export default {
    name: 'AuthRegisterSuccess'
  }
</script>

<style>
</style>
